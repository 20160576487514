export const handleActiveType = (categoryInput, titleInput) => {
  // RØDVIN
  const [category] = categoryInput;
  const [title] = titleInput;
  if (
    category.toLowerCase().match('rødvin') ||
    title.toLowerCase().match('rødvin')
  ) {
    return 'redWine';
  }

  // HVIDVIN
  if (
    category.toLowerCase().match('hvidvin') ||
    title.toLowerCase().match('hvidvin')
  ) {
    return 'whiteWine';
  }

  // ROSÉ
  if (
    category.toLowerCase().match('rosé') ||
    title.toLowerCase().match('rosé')
  ) {
    return 'rose';
  }

  // CHAMPAGNE
  if (
    category.toLowerCase().match('champagne') ||
    title.toLowerCase().match('champagne') ||
    title.toLowerCase().match('brut')
  ) {
    return 'champagne';
  }

  // MOUSSERENDE
  if (
    category.toLowerCase().match('mousserende') ||
    title.toLowerCase().match('mousserende')
  ) {
    return 'mousserende';
  }

  //PORTVIN
  if (
    category.toLowerCase().match('portvin') ||
    title.toLowerCase().match('portvin')
  ) {
    return 'portWine';
  }

  // DESSERT
  if (
    category.toLowerCase().match('dessert') ||
    title.toLowerCase().match('dessert')
  ) {
    return 'dessertWine';
  }

  return 'redWine';
};
