import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { device } from '../../providers/theme';
import { UserContext } from '../../providers/user';

const pulse = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.4);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`;

const Container = styled.div`
  margin: 32px 0;
  height: ${props => (props.isOpen ? '100%' : '60px')};
  overflow: hidden;

  @media ${device.tablet} {
    margin: 22px;
  }

  @media ${device.mobile} {
    margin: 0 22px;
  }
`;

const Header = styled.div`
  height: 60px;
  width: 100%;
  cursor: pointer;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  border-bottom: 1px solid #e2e2e2;
`;

const Note = styled.p`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  &.is-loading {
    width: 150px;
    animation: ${pulse} 2s linear infinite;
  }
`;

const Comment = styled.div`
  display: flex;
  background: #f1f1f1;
  margin: 8px 0;

  @media ${device.mobile} {
    flex-direction: column;
    border-left: 3px solid ${props => props.backgroundColor};
  }
`;

const CommentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  min-width: 120px;
  text-align: center;
  @media ${device.mobile} {
    margin: 10px;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

const CommentText = styled.div`
  font-size: 0.9rem;
  margin: 0;
  padding: 22px;
  border-left: 3px solid ${props => props.backgroundColor};
  @media ${device.mobile} {
    border-left: none;
    padding: 6px 10px 20px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: 100;
`;

const AddComment = styled.div`
  padding: 22px;
  background: #f1f1f1;

  .submit-btn {
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: ${props => props.backgroundColor};
    cursor: pointer;

    @media ${device.mobile} {
      width: 100%;
      margin-top: 12px;
    }
  }

  @media ${device.mobile} {
    padding: 12px;
  }
`;

const TextArea = styled.textarea`
  min-height: 120px;
  width: 100%;
  padding: 12px;
  background-color: #fcfcfc;
  font-size: 0.9rem;
`;

function CommentField({ themeColor, productType, productId }) {
  const [isOpen, setOpen] = useState(true);
  const { user } = useContext(UserContext);
  const [comment, setComment] = useState('');
  const [commentList, setList] = useState(null);

  const displayName = name => {
    return name.split(' ')[0];
  };

  return (
    <Container isOpen={isOpen}>
      <Header onClick={() => setOpen(!isOpen)}>
        <Note className={!commentList ? 'is-loading' : ''}>
          {!commentList ? (
            <>&nbsp;</>
          ) : (
            <>
              {commentList?.length
                ? `${commentList.length} kommentarer`
                : 'Ingen kommentarer'}
            </>
          )}
        </Note>
        <FaChevronDown size={22} />
      </Header>
      {user ? (
        <AddComment backgroundColor={themeColor}>
          <p>Vær med til at dele din oplevelse af vinen</p>
          <form>
            <TextArea
              onChange={e => setComment(e.target.value)}
              placeholder="Tilføj din kommentar"
            />
            <input
              className="submit-btn"
              type="button"
              value="Tilføj kommentar"
            />
          </form>
        </AddComment>
      ) : (
        <AddComment>
          Du skal <StyledLink to="/login">logge ind</StyledLink> for at tilføje
          kommentar.
        </AddComment>
      )}
      {commentList &&
        commentList.reverse().map(comment => (
          <Comment backgroundColor={themeColor}>
            <CommentName>{displayName(comment.name)}</CommentName>
            <CommentText backgroundColor={themeColor}>
              {comment.comment}
            </CommentText>
          </Comment>
        ))}
    </Container>
  );
}

export default CommentField;
