// Hent tekst ud fra key
export const wineInfo = {
  cerrodosanto: {
    title: 'Cerro do Santo dop Douro Reserva 2015',
    text:
      'Med en Cerro do Santo dop Douro Reserva 2015 rødvin, bliver du ikke skuffet. En vin, der næsten rammer alles forventninger samt er til at betale. Vinen har en kraftig fylde i smagen som skaber en dejlig, rund balance uden at have for meget garvesyre.\n\nCerro do Santo dop Douro Reserva 2015 er baseret på kirsebær og blommer\n og kan mere eller mindre drikkes alle tider på året.'
  },
  skarsogaard: {
    title: 'Skærsøgård vine',
    text:
      'Skærsøgård vine er en vingård med en særlig historie. Det er nemlig den første autoriseret vingård i Danmark. \nVinfabrikken er beliggende i Dons, omkring Kolding, hvor man her også har mulighed for at handle i deres butik.\nSkærsøgård vine har flere gange vundet hæderlige præmier både nationalt men også internationalt.'
  }
};
