import React from 'react';
import styled from 'styled-components';
import { device } from '../../providers/theme';
import { wineInfo } from './info';

const Container = styled.div`
  @media ${device.tablet} {
    margin: 22px;
  }
`;

const Title = styled.h2`
  margin-top: 60px;
`;

const Text = styled.p`
  white-space: break-spaces;
`;

function WineProvider({ wine }) {
  if (wine?.toLowerCase().match('cerro do santo dop douro reserva 2015')) {
    return (
      <Container>
        <Title>{wineInfo['cerrodosanto'].title}</Title>
        <Text>{wineInfo['cerrodosanto'].text}</Text>
      </Container>
    );
  }
  if (
    wine?.toLowerCase().match('skærsøgård') ||
    wine?.toLowerCase().match('skærsøgaard')
  ) {
    return (
      <Container>
        <Title>{wineInfo['skarsogaard'].title}</Title>
        <Text>{wineInfo['skarsogaard'].text}</Text>
      </Container>
    );
  }

  return null;
}

export default WineProvider;
