import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from '../components/layout';
import { device, ThemeContext } from '../providers/theme';
import { handlePrice } from '../utils/handlePrice';
import { handleOffer } from '../utils/handleOffer';
import { handleEncoding } from '../utils/handleEncoding';
import { handleActiveType } from './handleActiveType';
import SEO from '../components/seo';
import { titleCleanup } from './titleCleanup';
import { textCleanup } from './textCleanup';
import Breadcrumbs from '../components/Breadcrumbs';
import { handleTypeColor } from '../utils/typeColor';
import CommentField from '../components/CommentField';
import handlePopular from '../utils/handlePopular';
import WineProvider from '../components/WineProvider';
import { JSXSchema } from '../components/JSXSchema';
import Product from '../components/Product';
// import firebase from 'gatsby-plugin-firebase';

const pulse = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.4);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
`;

const lineClampOpen = keyframes`{
  from {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}`;
const lineClampClose = keyframes`{
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 3;
    -webkit-line-clamp: initial;
  }
}`;

const Container = styled.section`
  padding: 42px 0;
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
`;

const GeneratedText = styled.div`
  padding: 42px 0;
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 22px;
  border: 2px solid ${props => props.themeColor};
  border-radius: 6px;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Row = styled.div`
  background: ${props => props.theme.backgroundColors.main};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  margin-top: 32px;
`;

const ProductImage = styled.div`
  display: flex;
  align-items: center;
  height: 370px;
  width: 40%;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const WineImage = styled(LazyLoadImage)`
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

const Details = styled.div`
  padding: 0 22px;
  // height: 370px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.mobile} {
    margin: 22px 0;
    display: block;
    width: 100%;
  }

  .line-clamp {
    max-height: 158px;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

    &.open {
      max-height: 100rem;
      transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

      .description-text {
        animation: ${lineClampOpen} 0.1s linear 0s forwards;
      }
    }
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`;

const RelatedTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

const Description = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  animation: close 0.1s linear 0.1s forwards;

  margin: 22px 0;
  max-width: 460px;
  @media ${device.mobile} {
    max-width: 100%;
  }
`;

const Specifications = styled(Description)`
  white-space: pre-line;
`;

const Typo = styled.p`
  white-space: pre-line;
`;

const CurrentLikes = styled.label`
  display: inline-block;
  font-size: 16px;
  margin: 12px 0;
  border-radius: 0px;
  background: #e2e2e2;
  padding: 6px 12px;
  &.is-loading {
    width: 100px;
    border-radius: 30px;
    animation: ${pulse} 2s linear infinite;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 460px;
  
  @media ${device.mobile} {
  justify-content: space-between;
  margin-top: 12px;
`;

const LikeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  background: #fff;
  border: 2px solid ${props => props.themeColor};
  color: ${props => props.themeColor};
  padding: 12px 16px;
  outline: none;
  margin-left: 12px;
  font-weight: bold;
  height: 58px;

  &.liked {
    background: ${props => props.themeColor};
    color: #fff;
    cursor: auto;
  }

  @media ${device.mobile} {
    width: 40%;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  max-width: 220px;
  text-align: center;
  color: white;
  background: ${props => props.themeColor};
  box-shadow: 0 10px 13px -9px black;
  height: 58px;
  border-radius: 30px;
  border: 4px solid ${props => props.themeColor};
  transition: background 0.1s ease-in;
  font-weight: bolder;

  @media ${device.mobile} {
    width: 60%;
  }
`;

const Price = styled.span`
  display: block;
  font-weight: bold;
  font-size: 1.5rem;
`;

const Offer = styled.span`
  display: block;
  font-weight: bold;
  color: #777;
  font-size: 14px;
  text-decoration: line-through;
`;

const Expand = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

function ProductTemplate({
  pageContext: {
    productKey,
    productUrl,
    productTitle,
    productRedirect,
    productId,
    productPath,
    productDescription,
    productPrice,
    productOldprice,
    productLink,
    siblings,
    productCategory,
    productAvailable,
    productImage,
    productType,
    aiGenerated
  }
}) {
  const { setTheme } = useContext(ThemeContext);
  const [expand, setExpand] = useState(false);
  const [editMode, setEdit] = useState(false);
  const [post, setPost] = useState('');

  useEffect(() => {
    switch (productType) {
      case 'roedvin':
        setTheme('redWine');
      case 'hvidvin':
        setTheme('whiteWine');
      case 'rose':
        setTheme('rose');
      case 'champagne':
      case 'mousserende':
        setTheme('bubbles');
      case 'dessertvin':
      case 'portvin':
        setTheme('dessertWine');
      default:
        setTheme('redWine');
    }
  }, []);

  const handleTitle = text => {
    const title = handleEncoding(text);
    const cleanup = titleCleanup(title);
    return cleanup;
  };

  const handleDescription = (text, title) => {
    if (!text || !text[0].length) return;
    const encodedDescription = handleEncoding(text);
    const split = encodedDescription.split('.');
    let fixedText = encodedDescription;
    if (split.length > 1) {
      fixedText = split.slice(0, -1);
      fixedText = fixedText.join('. \n');
    }

    let [description, specifications] = textCleanup(
      fixedText,
      handleTitle(productTitle)
    );

    if (description?.length && description?.trim().slice(-1) !== '.') {
      description += '.';
    }

    return [description, specifications];
  };

  useEffect(() => {
    const editing = window.location.search.replace('?', '');
    if (editing === process.env.GATSBY_EDIT_KEY) {
      setEdit(true);
    }
  }, []);

  const handleSubmit = async event => {
    event.preventDefault(); // Prevent the default form submission

    // Create URL-encoded data
    const formData = new URLSearchParams();
    formData.append('post', post);
    formData.append('product', handleEncoding(productTitle));
    formData.append('domain', 'vinkammeret');
    formData.append('branch', 'master');
    formData.append('token', '6614c6dceab65560c3d6b979');

    // Send data using Fetch API
    await fetch('https://static8-api.herokuapp.com/cms/add-post', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData.toString()
    }).then(() => {
      alert('Teksten tilføjet om ~5 minutter');
    });
  };

  return (
    <React.Fragment>
      <SEO
        title={handleEncoding(productTitle)}
        description={`Læs mere omkring ${handleEncoding(
          productTitle
        )} og se hvor du netop kan købe den`}
      />
      <JSXSchema
        type="Product"
        name={handleEncoding(productTitle)}
        image={handleEncoding(productImage)}
        sku={new Date().getTime()}
        description={handleDescription(productDescription)?.[0] || ''}
        offers={{
          type: 'Offer',
          price: productPrice[0],
          priceCurrency: 'DKK',
          availability: 'In stock'
        }}
        aggregateRating={{
          type: 'AggregateRating',
          ratingValue: '4.8',
          reviewCount: '11'
        }}
      />
      <Layout
        title={
          handleActiveType(productCategory, productTitle) === 'champagne' ||
          handleActiveType(productCategory, productTitle) === 'mousserende'
            ? 'champagne'
            : ''
        }
        themeColor={handleTypeColor(productType)}
        withoutSeo
      >
        <Container>
          <Info>
            {productImage && (
              <ProductImage>
                <WineImage
                  width="100"
                  height="400"
                  fetchpriority="high"
                  src={productImage}
                  alt={handleTitle(productTitle)}
                />
              </ProductImage>
            )}

            <Details>
              <Title>{handleTitle(productTitle)}</Title>
              <div className={`line-clamp ${expand ? 'open' : ''}`}>
                <Description className="description-text">
                  {handleDescription(productDescription) &&
                    handleDescription(productDescription)[0]}
                </Description>
                {handleDescription(productDescription) &&
                  handleDescription(productDescription)[1] && (
                    <Specifications>
                      {handleDescription(productDescription)[1]}
                    </Specifications>
                  )}
              </div>
              <div style={{ margin: '12px 0' }}>
                {handleOffer(productOldprice, productPrice) && (
                  <Offer>{handleOffer(productOldprice, productPrice)},-</Offer>
                )}
                <Price>{handlePrice(productPrice)} kr</Price>
              </div>
              {/* {currentLikes !== null ? (
                <CurrentLikes>
                  {currentLikes > 0
                    ? `${currentLikes} anbefalinger`
                    : 'Ingen anbefalinger givet'}
                </CurrentLikes>
              ) : (
                <CurrentLikes className={!currentLikes ? 'is-loading' : ''}>
                  &nbsp;
                </CurrentLikes>
              )} */}
              <Footer>
                <StyledLink
                  themeColor={handleTypeColor(productType)}
                  exact
                  to={productRedirect}
                >
                  Gå til forhandler{' '}
                  <AiOutlineShoppingCart style={{ marginLeft: 8 }} size={20} />
                </StyledLink>
                <Expand onClick={() => setExpand(!expand)}>Læs mere</Expand>
              </Footer>
            </Details>
          </Info>
          <WineProvider wine={handleTitle(productTitle)} />
        </Container>
        {editMode && (
          <form onSubmit={handleSubmit}>
            <textarea
              style={{
                display: 'block',
                width: '100%',
                minHeight: 400,
                marginBottom: 12
              }}
              id="post"
              name="post"
              value={post}
              onChange={e => setPost(e.target.value)}
            ></textarea>
            <button type="submit">Gem artikel</button>
          </form>
        )}
        {aiGenerated && (
          <GeneratedText themeColor={handleTypeColor(productType)}>
            <h2>Vi anbefaler {productTitle}</h2>
            <Typo>{aiGenerated}</Typo>
          </GeneratedText>
        )}
        {siblings ? (
          <RelatedProducts siblings={siblings} />
        ) : (
          handlePopular(productType)
        )}
        <Breadcrumbs
          currentWine={handleTitle(productTitle)}
          type={productType}
        />
      </Layout>
    </React.Fragment>
  );
}

const RelatedProducts = ({ siblings }) => {
  const beautifyURL = title => {
    if (!title) {
      return '';
    }

    return title[0]
      .replace(/\s/g, '-')
      .replace(/-+/g, '-')
      .replace(/[^a-å0-9-]/gi, '')
      .toLowerCase();
  };

  return (
    <Container>
      <RelatedTitle>Andre kiggede også på</RelatedTitle>
      <Row>
        {siblings.map(pro => (
          <Product
            title={pro.produktnavn?.[0] || pro.Name?.[0]}
            description={pro.beskrivelse?.[0] || pro.Description?.[0]}
            price={pro.nypris?.[0] || pro.Price?.[0]}
            image={pro.billedurl?.[0] || pro.ImageUrl?.[0]}
            path={`/${pro.wineType}/${beautifyURL(
              pro.produktnavn || pro.Name
            )}`}
          />
        ))}
      </Row>
    </Container>
  );
};

export default ProductTemplate;
